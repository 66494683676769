<template>
    <v-form ref="fileUploadForm" v-model="validForm">
        <v-layout row class="pt-1">
            <v-flex pl-5 py-2 :class="standAlone ? 'xs6 lg5 md5' : 'xs6 lg3 md4'">
                <div class="d-flex flex-row align-center">
                    <div>
                        <v-icon class="pr-2">{{ fileIcon }}</v-icon>
                    </div>
                    <div class="text-no-wrap">{{ file.Document.doctype_id >= 999 ? (document != null && document.AssetDocument.asset_title != null ? document.AssetDocument.asset_title : file.Document.title) : file.Document.title }}</div>
                    <div style="width: 100%" class="text-right">
                        <v-icon :color="parseInt(file.Document.customeraccess) === 1 ? 'green darken-1' : 'red darken-1'">{{ parseInt(file.Document.customeraccess) === 1 ? 'mdi-lock-open-outline' : 'mdi-lock-outline' }}</v-icon>
                    </div>
                </div>
            </v-flex>
            <v-flex py-2 :class="standAlone ? 'xs6 lg4 md5' : 'xs6 lg3 md4'">
                <v-file-input
                        :disabled="parseInt(file.Document.linked) === 1"
                        :error-messages="manualErrorState"
                        :rules="uploadObject != null && uploadObject['type'] !== '' ? (module === 'shipping' ? [...[validationRules.fileSizes.size10Mb], ...validationRules.validUploadFiles] : [...validationRules.validUploadFiles]) : [true]"
                        accept="image/png, image/jpeg, application/pdf"
                        class="py-0"
                        dense
                        hide-details="auto"
                        solo
                        truncate-length="50"
                        v-model="uploadObject"
                        v-if="[305,401,402,403,404,405,406,407,408,409,410,411,412,413,414,415,416].includes(file.Document.doctype_id)"
                        @change="checkFile"
                />
                <v-file-input
                        :disabled="parseInt(file.Document.linked) === 1"
                        :error-messages="manualErrorState"
                        :rules="uploadObject != null && uploadObject['type'] !== '' ? (module === 'shipping' ? [...[validationRules.fileSizes.size15Mb], ...validationRules.validUploadFiles] : [...validationRules.validUploadFiles]) : [true]"
                        accept="image/png, image/jpeg, application/pdf"
                        class="py-0"
                        dense
                        hide-details="auto"
                        solo
                        truncate-length="50"
                        v-model="uploadObject"
                        v-if="[200,209].includes(file.Document.doctype_id)"
                        @change="checkFile"
                />
                <v-file-input
                        :disabled="parseInt(file.Document.linked) === 1"
                        :error-messages="manualErrorState"
                        :rules="uploadObject != null && uploadObject['type'] !== '' ? (module === 'shipping' ? [...[validationRules.fileSizes.size3Mb], ...validationRules.validUploadFiles] : [...validationRules.validUploadFiles]) : [true]"
                        accept="image/png, image/jpeg, application/pdf"
                        class="py-0"
                        dense
                        hide-details="auto"
                        solo
                        truncate-length="50"
                        v-model="uploadObject"
                        v-if="![200,209,305,401,402,403,404,405,406,407,408,409,410,411,412,413,414,415,416].includes(file.Document.doctype_id)"
                        @change="checkFile"
                />
            </v-flex>
            <div class="d-flex flex-row align-center">
                <v-btn
                    :loading="uploading"
                    :disabled="uploading || parseInt(file.Document.linked) === 1"
                    color="default"
                    small
                    @click="uploadFile()"
                >{{ $t('message.upload') }}</v-btn>
            </div>
            <div class="d-flex flex-row align-center" v-if="fileObject.AssetDocument.location != null">
                <div class="ml-5 d-flex flex-row align-center" v-if="fileObject.AssetDocument.uploaded">{{ $t('message.lastUpload') + ': ' + timestampToDate(parseInt(fileObject.AssetDocument.uploaded)) }}</div>
                <v-btn icon @click="viewFile(fileObject.AssetDocument.location)" small>
                    <v-icon class="pl-2" small>fas fa-cloud-download-alt</v-icon>
                </v-btn>
                <v-btn icon @click="deleteFile(fileObject)" small>
                    <v-icon class="pl-2" small>fas fa-trash</v-icon>
                </v-btn>
            </div>
            <div v-if="module === 'shipping' && fileObject.AssetDocument.location != null && parseInt(fileObject.AssetDocument.doctype_id) === 74">
                <v-btn
                    class="ml-3 mt-2"
                    small
                    @click="openImportPermitDialog()"
                >{{ $t('message.sendToSupplier')}}
                </v-btn>
                <v-dialog
                    :key="fileObject.AssetDocument.id"
                    persistent
                    transition="dialog-bottom-transition"
                    width="800px"
                    v-model="sendImportPermitDialog "
                >
                    <v-card>
                        <v-card-title class="success white--text">{{ $t('message.sendThisImportPermitToSupplier') }}</v-card-title>
                        <v-card-text class="white">
                            <v-row no-gutters class="pt-3 align-center">
                                <v-col cols="12">
                                    <h2>{{ $t('message.selectContacts') }}</h2>
                                    <v-simple-table class="appic-table-light specification-tables" dense>
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="grey lighten-3 pa-0"></th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="supplierImportPermitContacts.length > 0">
                                            <tr v-for="contact in supplierImportPermitContacts" v-bind:key="contact.Contact.id">
                                                <td>
                                                    <v-checkbox
                                                        :value="contact.Contact.email"
                                                        class="mt-1"
                                                        dense
                                                        hide-details
                                                        v-model="selectedSupplierContacts"
                                                    />
                                                </td>
                                                <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                                <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                                <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                                <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</td>
<!--                                                <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsshipping == 1 ? $t('message.yes') : $t('message.no') }}</td>-->
<!--                                                <td class="pt-2 pl-0 text-center">{{ contact.Contact.accounts == 1 ? $t('message.yes') : $t('message.no') }}</td>-->
                                            </tr>
                                            </tbody>
                                            <tbody v-else>
                                            <tr>
                                                <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="pr-3 pt-3 white">
                            <v-spacer/>
                            <v-btn
                                class="ml-3"
                                color="default"
                                small
                                @click="sendImportPermitDialog = false"
                            >{{ $t('message.cancel') }}
                            </v-btn>
                            <v-btn
                                :loading="loading.send"
                                class="ml-3"
                                color="info"
                                small
                                @click="sendPermitToSupplier()"
                            >{{ $t('message.send') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <AlertMissingValues
                    :alert_message="dialogs.error_message"
                    :dialog.sync="dialogs.error"
                    @dialog-closed="dialogClosed"
                />
            </div>
            <div v-if="module === 'shipping' && fileObject.AssetDocument.location != null && parseInt(fileObject.AssetDocument.doctype_id) === 303">
                <v-btn
                    class="ml-3 mt-2"
                    small
                    @click="openContainerReportDialog()"
                >{{ $t('message.sendToSupplier')}}
                </v-btn>
                <v-dialog
                    :key="fileObject.AssetDocument.id"
                    persistent
                    transition="dialog-bottom-transition"
                    width="800px"
                    v-model="sendContainerReportDialog"
                >
                    <v-card>
                        <v-card-title class="success white--text">{{ $t('message.sendThisContainerPreloadingInspectionReportToSupplier') }}</v-card-title>
                        <v-card-text class="white">
                            <v-row no-gutters class="pt-3 align-center">
                                <v-col cols="12">
                                    <h2>{{ $t('message.selectContacts') }}</h2>
                                    <v-simple-table class="appic-table-light specification-tables" dense>
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="grey lighten-3 pa-0"></th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                                <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="supplierImportPermitContacts.length > 0">
                                            <tr v-for="contact in supplierImportPermitContacts" v-bind:key="contact.Contact.id">
                                                <td>
                                                    <v-checkbox
                                                        :value="contact.Contact.email"
                                                        class="mt-1"
                                                        dense
                                                        hide-details
                                                        v-model="selectedSupplierContacts"
                                                    />
                                                </td>
                                                <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                                <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                                <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                                <td class="pt-2 pl-0 text-center">{{ parseInt(contact.Contact.otsnotification) === 1 ? $t('message.yes') : $t('message.no') }}</td>
                                            </tr>
                                            </tbody>
                                            <tbody v-else>
                                            <tr>
                                                <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="pr-3 pt-3 white">
                            <v-spacer/>
                            <v-btn
                                class="ml-3"
                                color="default"
                                small
                                @click="sendContainerReportDialog = false"
                            >{{ $t('message.cancel') }}
                            </v-btn>
                            <v-btn
                                :loading="loading.send"
                                class="ml-3"
                                color="info"
                                small
                                @click="sendContainerReportRequestToSupplier()"
                            >{{ $t('message.send') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <AlertMissingValues
                    :alert_message="dialogs.error_message"
                    :dialog.sync="dialogs.error"
                    @dialog-closed="dialogClosed"
                />
            </div>
            <div class="d-flex flex-row align-center ml-5" v-if="parseInt(file.Document.doctype_id) === 93">
                <div>{{ $t('message.buyerPoNo') }}</div>
                <v-text-field
                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                    :value="Contract__customer_pi_no"
                    class="force-text-left ml-3"
                    clearable
                    dense
                    hide-details="auto"
                    solo
                    @change="Contract__customer_pi_no = $event"
                />
            </div>
            <div class="d-flex flex-row align-center ml-5" v-if="parseInt(file.Document.doctype_id) === 94">
                <div>{{ $t('message.supplierPiNo') }}</div>
                <v-text-field
                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                    :value="Contract__supplier_pi_no"
                    class="force-text-left ml-3"
                    clearable
                    dense
                    hide-details="auto"
                    solo
                    @change="Contract__supplier_pi_no = $event"
                />
            </div>
            <template v-if="['contract','purchaseorder'].includes(module) && [95,101,102].includes(file.Document.doctype_id)">
                <v-checkbox
                    :label="module === 'contract' ? $t('message.mergeToContractPdf') : $t('message.mergeToPoPdf')"
                    :true-value="1"
                    class="mt-4 ml-3"
                    dense
                    hide-details="auto"
                    solo
                    v-if="fileObject.AssetDocument.id != null"
                    v-model="fileObject.AssetDocument.merge_attachments_to_main_pdf"
                    @change="updateMergeSetting()"
                />
            </template>
        </v-layout>
    </v-form>
</template>

<script>
    import {mapFields} from "vuex-map-fields"
    import S3 from 'aws-s3-pro'
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, log, hasScript} from "Helpers/helpers";
    import {api} from "Api";
    import AlertMissingValues from "Components/Appic/AlertMissingValues";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

    export default {
        name: "FileUpload",
        components: {AlertMissingValues},
        props: ['document','file','fileNamePrefix','module','updateMode','standAlone'],
        data(){
            return {
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                loader: false,
                loading: {
                    send: false
                },
                manualErrorState: '',
                uploading: false,
                uploadObject: {name: '', type: ''},
                fileObject: {
                    AssetDocument: {
                        id: this.document?.AssetDocument?.id,
                        asset: this.document?.AssetDocument?.asset,
                        asset_id: this.document?.AssetDocument?.asset_id,
                        customeraccess: this.document?.AssetDocument?.customeraccess,
                        doctype_id: this.document?.AssetDocument?.doctype_id,
                        asset_title: this.document?.AssetDocument?.asset_title,
                        location: this.document?.AssetDocument?.location,
                        asset_type: this.document?.AssetDocument?.asset_type,
                        uploaded: this.document?.AssetDocument?.uploaded,
                        merge_attachments_to_main_pdf: this.document?.AssetDocument?.merge_attachments_to_main_pdf
                    }
                },
                selectedSupplierContacts: [],
                sendContainerReportDialog: false,
                sendImportPermitDialog: false,
                supplierContacts: [],
                validForm: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('contract',{
                Contract__customer_pi_no: 'Contract.customer_pi_no',
                Contract__supplier_pi_no: 'Contract.supplier_pi_no'
            },'statePrefix'),
            ...mapFields(module, {
                // Contract__partial_no: 'current.Contract.partial_no',
                // Contract__revision_no: 'current.Contract.revision_no',
                // Contract__title: 'current.Contract.title',
                Contract__id: 'current.Contract.id',
                Contract__documents: 'current.Contract.documents'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('user',{
                s3Keys : 's3Keys'
            }),
            ...mapGetters([
                'validationRules'
            ]),
            baseURL(){
                return 'https://apptimber-appic.s3-ap-southeast-1.amazonaws.com'
            },
            config() {
                return {
                    bucketName: 'apptimber-appic',
                    dirName: this.file.Document.folder,
                    region: 'ap-southeast-1',
                    accessKeyId: this.s3Keys.id,
                    secretAccessKey: this.s3Keys.secret,
                    s3Url: this.baseURL
                }
            },
            fileType () {
                return this.document?.AssetDocument?.asset_type
            },
            fileName () {},
            fileIcon () {
                let icon = null
                switch(this.fileType){
                    case 'pdf':
                        icon = 'far fa-file-pdf'
                        break;
                    case 'bmp':
                        icon = 'far fa-file-image'
                        break;
                    case 'jpg':
                        icon = 'far fa-file-image'
                        break;
                    case 'png':
                        icon = 'far fa-file-image'
                        break;
                    case 'xls':
                        icon = 'far fa-file-excel'
                        break;
                    case 'xlxs':
                        icon = 'far fa-file-excel'
                        break;
                    default:
                        icon = 'far fa-file'
                }
                return icon
            },
            newFileName(){
                const originalNewFileName = this.file.Document.fileName;
                let newFileName = decodeURI(originalNewFileName);

                newFileName = newFileName.split("(").join("_");
                newFileName = newFileName.split(")").join("_");
                newFileName = newFileName.split(" ").join("_");

                if(newFileName != null && newFileName.length > 0){
                    if(this.fileNamePrefix !== '') {
                        return `${this.fileNamePrefix}_${newFileName}_v${Math.random().toString().slice(2, 5)}`
                    } else {
                        return `${newFileName}_v${Math.random().toString().slice(2, 5)}`
                    }
                } else {
                    return `${this.uploadObject['name'].split('.').slice(0, -1).join()}_v${Math.random().toString().slice(2, 5)}`
                }
            },
            S3Client(){
                return new S3(this.config);
            },
            supplierImportPermitContacts () {
                return this.supplierContacts.filter(contact => {
                    return parseInt(contact?.Contact?.otsnotification) === 1
                    // return contact?.Contact?.otsshipping == 1
                })
            },
            url(){
                return `${this.baseURL}/${this.file.Document.folder}/${this.newFileName}`;
            }
        },
        methods: {
            ...mapActions('document',{
                deleteDocument: 'deleteDocument',
                sendContainerInspectionReportRequestToSupplier: 'sendContainerInspectionReportRequestToSupplier',
                sendImportPermitToSupplier: 'sendImportPermitToSupplier'
            }),
            checkFile(fileObj){
                if(fileObj) {
                    const allowedExtensions = this.validationRules.validUploadFileExtensions
                    if (fileObj.type === "" || allowedExtensions.exec(fileObj.name) == null) {
                        this.manualErrorState = 'PNG or JPG images or PDF only.'
                    } else {
                        //check file name if valid
                        if(!hasScript(fileObj.name)) {
                            this.manualErrorState = ''
                        } else {
                            this.manualErrorState = 'Invalid file name.'
                        }
                    }
                }
            },
            async deleteFile(obj){
                if (await this.$root.$confirm(this.$t('message.deleteDocument'), this.$t('message.confirmations.continueDocumentDelete'), {color: 'orange'})) {
                    if (obj.AssetDocument.id != null) {
                        this.deleteDocument(obj.AssetDocument.id)
                            .then((result) => {
                                if(result === 'done'){
                                    this.fileObject.AssetDocument.id = null
                                    this.fileObject.AssetDocument.asset = null
                                    this.fileObject.AssetDocument.asset_id = null
                                    this.fileObject.AssetDocument.doctype_id = null
                                    this.fileObject.AssetDocument.asset_title = null
                                    this.fileObject.AssetDocument.location = null
                                    this.fileObject.AssetDocument.asset_type = null
                                    this.fileObject.AssetDocument.uploaded = null
                                    this.fileObject.AssetDocument.customeraccess = null
                                    this.fileObject.AssetDocument.merge_attachments_to_main_pdf = null
                                    this.$emit('delete-file-done', obj.AssetDocument.id)
                                    // if(this.uploadObject) this.uploadObject.name = ''
                                    this.uploadObject = new File([],'')
                                } else {
                                    this.fileObject.AssetDocument.id = null
                                    this.fileObject.AssetDocument.asset = null
                                    this.fileObject.AssetDocument.asset_id = null
                                    this.fileObject.AssetDocument.doctype_id = null
                                    this.fileObject.AssetDocument.asset_title = null
                                    this.fileObject.AssetDocument.location = null
                                    this.fileObject.AssetDocument.asset_type = null
                                    this.fileObject.AssetDocument.uploaded = null
                                    this.fileObject.AssetDocument.customeraccess = null
                                    this.fileObject.AssetDocument.merge_attachments_to_main_pdf = null
                                    // this.$emit('delete-file-done', obj.AssetDocument.id)
                                    // if(this.uploadObject) this.uploadObject.name = ''
                                    this.uploadObject = new File([],'')
                                    this.$toast.error(this.$t('message.errors.fileNotDeleted'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                    }
                }
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            getSupplierContacts () {
                if(this.module === 'shipping') {
                    let company_id = this.$store.state.appic.shipment.current.Shipment.Supplier.company_id
                    return new Promise((resolve, reject) => {
                        api
                            .get("/companies/" + company_id + "/contacts")
                            .then(response => {
                                this.supplierContacts = response.data.data
                                resolve('done');
                            })
                            .catch(error => {
                                reject(error);
                            })
                            .finally(() => {
                                resolve('done');
                            })
                    })
                }
            },
            openImportPermitDialog () {
                this.getSupplierContacts()
                    .then(() => {
                        this.sendImportPermitDialog = true
                        this.supplierImportPermitContacts.forEach(c => {
                            this.selectedSupplierContacts.push(c.Contact.email)
                        })
                    })
            },
            openContainerReportDialog () {
                this.getSupplierContacts()
                    .then(() => {
                        this.sendContainerReportDialog = true
                        this.supplierImportPermitContacts.forEach(c => {
                            this.selectedSupplierContacts.push(c.Contact.email)
                        })
                    })
            },
            renameUploadObject (file, newFileName) {
                this.uploadObject = new File([file],newFileName)
            },
            sendContainerReportRequestToSupplier() {
                if(this.selectedSupplierContacts.length === 0){
                    this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                    this.dialogs.error = true
                } else {
                    this.loading.send = true
                    let payload = {
                        contacts: this.selectedSupplierContacts,
                        contractId: this.fileObject.AssetDocument.asset_id,
                        fileLocation: this.fileObject.AssetDocument.location
                    }
                    this.sendContainerInspectionReportRequestToSupplier(payload)
                        .then((status)=>{
                            if (status === 'done') {
                                this.$toast.success(this.$t('message.successes.containerReportRequestSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            } else {
                                this.$toast.error(this.$t('message.errors.containerReportRequestNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.containerReportRequestNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.send = false
                            this.selectedSupplierContacts = []
                            this.sendContainerReportDialog = false
                        })
                }
            },
            sendPermitToSupplier () {
                if(this.selectedSupplierContacts.length === 0){
                    this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                    this.dialogs.error = true
                } else {
                    this.loading.send = true
                    let payload = {
                        contacts: this.selectedSupplierContacts,
                        contractId: this.fileObject.AssetDocument.asset_id,
                        fileLocation: this.fileObject.AssetDocument.location
                    }
                    this.sendImportPermitToSupplier(payload)
                        .then((status)=>{
                            if (status === 'done') {
                                this.$toast.success(this.$t('message.successes.shippingInstructionSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            } else {
                                this.$toast.error(this.$t('message.errors.shippingInstructionNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.shippingInstructionNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.send = false
                            this.selectedSupplierContacts = []
                            this.sendImportPermitDialog = false
                        })
                }
            },
            timestampToDate(timestamp) {
                return new Date(timestamp).toLocaleString();
            },
            updateMergeSetting() {
                this.$nextTick(() => {
                    const document = {
                        id: this.fileObject.AssetDocument.id,
                        merge_attachments_to_main_pdf: this.fileObject.AssetDocument.merge_attachments_to_main_pdf
                    }
                    // console.log(document)
                    api
                        .put('/documents/' + this.fileObject.AssetDocument.id,
                            {
                                document: document
                            }
                        )
                        .then((response)=>{
                            if(response.data.status === 'success') {
                                this.$emit('merge-setting-update-done')
                                this.$toast.success(this.$t('message.successes.settingUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.settingNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                })
            },
            uploadFile() {
                if(this.$refs.fileUploadForm.validate() && this.manualErrorState === '') {
                    if (this.uploadObject != null && this.uploadObject['name'] !== '') {
                        let file = this.uploadObject
                        this.uploading = true
                        this.S3Client
                            .uploadFile(file, this.newFileName)
                            .then((response) => {
                                if (response.status === 204) {
                                    this.fileObject.AssetDocument.id = null
                                    this.fileObject.AssetDocument.asset = this.module
                                    this.fileObject.AssetDocument.asset_id = null
                                    this.fileObject.AssetDocument.doctype_id = this.file.Document.doctype_id
                                    this.fileObject.AssetDocument.asset_title = this.file.Document.title
                                    this.fileObject.AssetDocument.location = response.location
                                    this.fileObject.AssetDocument.uploaded = Date.now()
                                    this.fileObject.AssetDocument.customeraccess = this.file.Document.customeraccess
                                    let type = file.type
                                    this.fileObject.AssetDocument.asset_type = type.split('/')[1]
                                    this.fileObject.AssetDocument.merge_attachments_to_main_pdf = null

                                    //rename the file input value
                                    const fileExtension = file.name.split('.').slice(-1)
                                    this.renameUploadObject(file, this.newFileName + '.' + fileExtension)

                                    this.$emit('upload-file-done', this.fileObject)
                                }
                            })
                            .catch((e) => {
                                this.$toast.error(this.$t('message.errors.uploadFailed'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.uploading = false
                            })
                            .finally(() => {
                                this.uploading = false
                            })
                    } else {
                        this.$toast.error(this.$t('message.errors.fileEmpty'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                } else {
                    this.$toast.error(this.$t('message.errors.fileNotValid'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            },
            viewFile(file) {
                if(file.includes('appic-pdf.apptimber.com') === false) {
                    const rand = new Date().getTime()
                    var tab = window.open(file + '?v=' + rand, '_blank')
                } else {
                    tab = window.open(file, '_blank')
                }
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        created(){
            if(this.document?.AssetDocument?.location){
                this.manualErrorState = ''
                let location = this.document.AssetDocument.location
                this.uploadObject.name = location.substring(location.lastIndexOf('/')+1)
            }
        }
    }
</script>

<style scoped>

</style>
